* {
    box-sizing: border-box;
  }
  @font-face {
      font-family: "Font";
      src:url(https://fonts.gstatic.com/s/notosans/v7/o-0IIpQlx3QUlC5A4PNr5TRG.woff);
  }
  @font-face {
    font-family: 'Whitney Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Whitney Medium'), url('fonts/whitneymedium.woff') format('woff');
    }
    @font-face {
        font-family: 'Whitney Light';
        font-style: normal;
        font-weight: normal;
        src: local('Whitney Light'), url('fonts/whitneybook.woff') format('woff');
        }
  html,
  body,
  #root,
  canvas {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: ;
    font-family: "Whitney Medium";
    overflow: hidden;
  }
  canvas { outline: none; border: none; background:#2f3136;}

  body {
    background: white;
  }
  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
  }
  a {text-decoration: none; color:#72767d;}
  .heading {
      position:fixed;
      z-index:999999999;
      font-size: 50px;
      font-family: Helvetica Neue;
      left: 15px;
      top: 15px;
      color: black;
  }
  hash {color:#72767d;padding-right: 10px;}
  /***SIDEBAR***/
  .sidebar {
      width: 20%;
      height: 100%;
      background:#2f3136;
      color: #fff;
      height: 100vh;
      overflow:scroll;
      float:left;
      
  }
  .sidebar .titlebar {
      padding: 20px;
      font-weight: 500;
      font-size: 20px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
 }
 .sidebar ul.channels {
     list-style: none;
     padding:20px;
     margin:0;
     line-height: 150%;
 }
 .sidebar ul.channels li {
    font-size: 20px;
    font-weight: 500;
    padding: 12px;
    margin-bottom: 5px;
    color: #72767d;
 }
 .sidebar ul.channels li.active {
     background: #40444b;
     color: #fff;
     border-radius: 5px;
 }
 .sidebar ul.channels li.active a {color:#fff;}
 .sidebar ul.channels li.title {
     font-size: 16px;
     text-transform: uppercase;
     color: gray;
     padding: 0px;
     margin-bottom: 10px;
 }
 .channel {
     width: 30%;
     background: #36393f;
     overflow:hidden;
     float:left;
     height:100vh;
 }
 .channel .inner {
     display: flex;
     height: 100%;
 }
 .channel .titlebar {
    padding: 20px;
    font-weight: 500;
    font-size: 20px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 99;
    position: relative;
 }
 .channel .chat {
    margin-top: 0px;
    padding: 0px;
    width: calc(100% - 30px); 
    height: calc(100% - 63px);
    overflow-y: scroll; 
    overflow-x: hidden; 
    float: left; 
    position: relative;
}
.channel .chat ul {
    list-style: none;
    padding: 0;
    margin: 30px;
    margin-top:15px;
    padding-bottom: 30px;
    width: 100%;
}
.channel .chat ul li {
    margin: 0px;
    padding-bottom: 12px;
    padding-top: 12px;
    font-size: 20px;
    color: #fff;
    line-height: 125%;
}
.channel .chat ul li .reply_img img {
    max-height: 200px;
    max-width: 100%;
    width: auto;
    background:#2f3136;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 15px;

}
.channel .chat ul li .thumb{
    width: 100%;
    height: auto;
}
.channel .chat ul li.reply {
    background:;
}
.channel .chat ul li .meta {
    display: inline-block;
    vertical-align: top;
    width: 70%;
}
.channel .chat ul li .meta .author date {
    color: #73767c;
    font-size: 15px;
    padding-left: 2px;
}
.channel .chat ul li .meta .body {
    font-family: 'Whitney Light';
}
.channel .chat ul li .img {
    display: inline-block;
    margin-right: 20px;
}
.channel .chat ul li .img img {
    width: 50px;
    height: 50px;
}
.inputwrap {
    position:fixed;
}
.channel .chat .inputfield {
    position: sticky;
    bottom: 0px;
    width: 100%%;
    margin-left: 30px;
    background: #36393f;
    padding-bottom: 30px;
    margin-top: -20px;
}
.channel .chat .inputfield.replying input {
    background: ;
    margin-top: 0px;
    border-radius: 0 0 13px 13px;

}
.channel .chat .inputfield .replyingto{display:none;}
.channel .chat .inputfield.replying .replyingto {
    width: 100%;
    padding: 20px;
    background: #2f3136;
    outline: none;
    border: 0;
    font-size: 20px;
    color: #dcddde;
    margin-top: 0px;
    font-family: 'Whitney Light';
    display: block;
    position: absolute;
    z-index:-1;
    margin-top:-60px;
    border-radius: 13px 13px 0 0;

}
.channel .chat .inputfield.replying .replyingto .closebutton {
    float: right;
    width: 20px;
    height: 20px;
    background-image:url(Icons/Close.svg);
    background-size:cover;
    opacity: 0.7;
    vertical-align: middle;
    display:inline-block;
}
.channel .chat .inputfield.replying .replyingto .closebutton:hover{opacity:0.85}
.channel .chat .inputfield input {
    width: 100%;
    padding: 20px;
    background: #41444a;
    border-radius: 13px;
    outline: none;
    border: 0;
    font-size: 20px;
    color: #dcddde;
    font-family: 'Whitney Light';

}
.sidebar .users {
    background:#2f3136;
    color: #72767d;
    padding: 30px;
}
.sidebar .users ul {
    list-style: none;
    padding:20px;
    margin:0;
    padding:0;
    line-height: 150%;
}
.sidebar .users ul li {
        font-size: 20px;
        font-weight: 500;
        padding: 0px;
        margin-bottom: 20px;
        color: #b9bbbe;
        vertical-align: middle;
        text-transform: lowercase;
}
.sidebar .users ul li .pic {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
    background-size: cover;
    background-position: center center;
    display: inline-block;
    vertical-align: middle;
}
.sidebar .users ul li .name {
    display: inline-block;
    vertical-align: middle;
}
.sidebar .users ul li.title {
    font-size: 16px;
    text-transform: uppercase;
    color: gray;
    padding: 0px;
}
.loginpopup.hide, .bgoverlay.hide{display:none;}
.loginpopup {
    position: fixed;
    padding: 40px;
    background: #41444a;
    border-radius: 13px;
    outline: none;
    border: 0;
    font-size: 20px;
    color: #dcddde;
    margin-top: -20px;
    font-family: 'Whitney Light';
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index:9999999;
    box-shadow: 0px 0px 10px #00000041;
    line-height: 200%;
}
.loginpopup .intro {
    font-size: 13px;
}
.loginpopup input {
    outline: none;
    padding: 20px;
    font-size: 15px;
    background: #3b3d42;
    font-family: 'Whitney Medium';
    outline:none;
    border:0;
    border-radius: 5px;
    margin-top: 20px;
    width: 100%;
    color: #fff;
}
.bgoverlay {
    width: 100%;
    height: 100%;
    overflow:hidden;
    position: fixed;
    left: 0;
    top: 0;
    background:#00000057;
    z-index:999;
}
.scene {
    width: 50%;
    float: left;
    height: 100vh;
}
.scene .workdetails.hidden{display: none;}
.scene .workdetails {
    position: fixed;
    width: 20%;
    padding: 25px;
    background:#2f3136;
    border-radius: 10px;
    z-index: 9999999;
    bottom: 20px;
    right: 20px;
    color:#dcddde;
    line-height: 200%;
    box-shadow: 0px 0px 10px #00000041;
    font-family: 'Whitney Light';
}
.scene .workdetails .title {
    font-size: 20px;
    font-family: 'Whitney Medium';
}
.scene .workdetails .artist {
    font-size: 20px;
}
.scene .workdetails .desc{
    color: #73767c;
    font-size: 20px;

}
.scene .workdetails .desc a{
    color: #adb0b6;
}
.videopopup.hidden {display: none;}
.videopopup {
    width: 30%;
    position: absolute;
    z-index: 9999999;
    border-radius: 15px;
    box-shadow: 0px 0px 10px #00000041;
    overflow: hidden;
    top: 50px;
    left: 50%;
}
.videopopup video{
    width: 100%;
    height: auto;
}
.scene .videoPlayer {
    display: none;
    z-index:99999;
}
.react-player {
    line-height: 0px;;
}
video {
    background:black;
    outline:none;
}
.emojis{
margin-top:10px;
}
.emojis .emoji{
    background-color: #41444a;
    border-radius: 3px;
    padding: 5px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    line-height:0px;
    height: 25px;
}
.emojis .emoji number{
    font-size: 10px;
    padding-left: 5px;
    color: #707379;
    font-family: 'Whitney Medium';
    font-weight: 600;
}
.emojis .emoji:hover {
    background-color: #555961;
}
.emojis .emoji:hover number {
    color: #9498a0;
}
.emojis .emoji img {
    height: 15px;
    width: auto;
    vertical-align: middle;
    display:inline-block;
}
.emojis .emoji.lol img, .emojis .emoji.sweet img {
    height: 10px;
    margin-top: 5.5%;
    margin-bottom: 2.5%;
}
